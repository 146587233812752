button:
  about: О шаблоне
  back: Назад
  go: Перейти
  home: Главная
  toggle_dark: Включить темный режим
  toggle_langs: Сменить язык
intro:
  desc: Самостоятельный начальный шаблон Vite
  dynamic-route: Демо динамического маршрута
  hi: Привет, {name}!
  whats-your-name: Как тебя зовут?
not-found: Не найден
